<template>
  <v-card class="d-flex flex-column grey lighten-2">
    <ADNewAssignmentItem
      ref="newAssignmentItem"
      v-for="(newAssignmentData, i) in newAssignmentDatas"
      :key="`new-fc-assignment-${i + 1}`"
      :datasIndex="i"
    />
    <v-card-actions class="d-flex justify-end pr-4 pb-4">
      <v-btn
        v-if="!isEditMode && !bulkEditing && user.user.userRole !== 'STAFF'"
        depressed
        class="mx-3 grey lighten-2"
        :loading="assignmentsProcessing"
        @click="handleAddingAnotherAssignment"
      >
        <v-icon>mdi-plus</v-icon>
        Add another assignment
      </v-btn>
      <v-btn
        v-if="!isEditMode && !bulkEditing && user.user.userRole !== 'STAFF'"
        depressed
        class="mx-3 grey lighten-2"
        :loading="assignmentsProcessing"
        @click="handleAssignmentDuplication"
      >
        <v-icon>mdi-plus</v-icon>
        Duplicate
      </v-btn>
      <v-scale-transition>
        <div v-if="deleteConfirmation" class="d-flex align-center">
          <h3>Are you sure?</h3>
          <v-btn
            class="ml-2"
            color="success"
            @click="handleAssignmentsDeletion"
          >
            Yes
          </v-btn>
          <v-btn class="ml-2" color="error" @click="deleteConfirmation = false">
            No
          </v-btn>
        </div>
      </v-scale-transition>
      <v-scale-transition>
        <div v-if="updateConfirmation" class="d-flex align-center">
          <h3>Are you sure? You will modify 10 assignments!</h3>
          <v-btn
            class="ml-2"
            color="success"
            @click="handleAssignmentsUpdate"
          >
            Yes
          </v-btn>
          <v-btn class="ml-2" color="error" @click="updateConfirmation = false">
            No
          </v-btn>
        </div>
      </v-scale-transition>
      <v-btn
        v-if="isEditMode && !bulkEditing && user.user.userRole !== 'STAFF'"
        depressed
        class="mx-3 grey lighten-2"
        :loading="deletionProcessing || assignmentsProcessing"
        :disabled="deleteConfirmation"
        @click="handleEditAssignmentCopy"
      >
        <v-icon>mdi-plus</v-icon>
        Add Copy as New
      </v-btn>
      <v-btn
        v-if="!bulkEditing && user.user.userRole !== 'STAFF'"
        width="250px"
        color="secondary"
        :loading="deletionProcessing || assignmentsProcessing"
        :disabled="deleteConfirmation"
        @click="handleExportToGoogle"
        >Export to Google Sheet</v-btn
      >
      <v-btn
        :width="bulkEditing ? '120px' : '100px'"
        color="secondary"
        class="mx-3"
        :loading="deletionProcessing || assignmentsProcessing"
        :disabled="deleteConfirmation"
        @click="handleAssignmentsProcessing"
        >{{
          isEditMode ? "Update" : bulkEditing ? "Update All" : "Create"
        }}</v-btn
      >
      <v-btn
        v-if="isEditMode && !bulkEditing && user.user.userRole !== 'STAFF'"
        class="ml-4"
        height="36px"
        small
        color="secondary"
        :loading="deletionProcessing || assignmentsProcessing"
        :disabled="deleteConfirmation"
        @click="deleteConfirmation = true"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
// vuex
import { mapActions, mapGetters } from "vuex";
// libraries
import _ from "lodash";
// internal
import {
  BLANK_NEW_ASSIGNMENT_DATAS,
  processAssignmentsWithDatas,
  formatBackendData
} from "@/utils/assignmentUtils";
import {
  deleteAssignment,
  getAssignments,
  getTasks,
  processTask,
  updateAssignmentsList,
  processDubEditor
} from "@/utils/newDbUtils";
import {
  addRowsToGoogleSheet,
  SPREADSHEET_ID,
} from "@/utils/googleSheetsUtils";
// components
import ADNewAssignmentItem from "@/components/AssignmentDashboard/ADNewAssignmentItem";

export default {
  name: "ADNewAssignmentModal",
  components: { ADNewAssignmentItem },
  data() {
    return {
      assignmentsProcessing: false,
      deleteConfirmation: false,
      deletionProcessing: false,
      updateConfirmation: false,
    };
  },
  computed: {
    ...mapGetters("assignment", [
      "newAssignmentDatas",
      "isEditMode",
      "bulkEditing",
      "bulkQueryEdit",
      "projectsList"
    ]),
    ...mapGetters("spreadsheetUpload", ["selectedDashboardRows"]),
    ...mapGetters("auth", ["user"]),
  },
  methods: {
    ...mapActions("assignment", [
      "setNewAssignmentDatas",
      "setNewAssignmentModalOpen",
      "setBulkQueryEdit",
      "setUpdateBatchConfirmation"
    ]),
    ...mapActions("spreadsheetUpload", ["setSelectedDashboardRows"]),
    ...mapActions("flashMessage", ["handleFlash"]),
    handleAddingAnotherAssignment() {
      let newPayload = _.cloneDeep(this.newAssignmentDatas);
      newPayload = [...newPayload, ...BLANK_NEW_ASSIGNMENT_DATAS];
      this.setNewAssignmentDatas(newPayload);
    },
    handleAssignmentDuplication() {
      let newPayload = _.cloneDeep(this.newAssignmentDatas);
      let lastAssn = _.last(newPayload);
      newPayload = [...newPayload, lastAssn];
      this.setNewAssignmentDatas(newPayload);
    },
    handleEditAssignmentCopy() {
      const copyItem = {
        ...this.newAssignmentDatas[0],
        assignmentDetails: {
          ...this.newAssignmentDatas[0].assignmentDetails,
          status: "Assigned",
        },
      };
      this.setNewAssignmentDatas([_.omit(copyItem, "id")]);
    },
    confirmFormValid() {
      let validator = [];
      this.$refs.newAssignmentItem.forEach((el) =>
        validator.push(el.validateForm())
      );
      return validator.every((value) => value === true);
    },
    filterNullValues(obj) {
      const result = {};
      for (const key in obj) {
        if (obj[key] !== null && typeof obj[key] !== "undefined") {
          if (typeof obj[key] === "object") {
            const item = this.filterNullValues(obj[key]);
            Object.assign(result, item);
          } else {
            result[key] = obj[key];
          }
        }
      }
      return result;
    },
    bulkQueryEditDataPrepare() {
      const query = this.$router.history.current.query;
      if (query && query.Project) {
        const project = this.projectsList.find(el => el.name === query.Project);
        if (project) {
          query.projectId = project.id;
          delete query.Project;
        }
        else delete query.Project
      }
      let requestQuery = "?";
      Object.keys(query).forEach((key) => {
        if (key === "ppm") {
          requestQuery += `ppm=${query[key].toUpperCase()}&`;
        } else  requestQuery += `${key}=${query[key]}&`;
      })
      const bulkEditBatch = this.selectedDashboardRows.map((el) => ({
        ...this.newAssignmentDatas[0],
      }));
      const backendUploadDatas = bulkEditBatch.map((data) => {
        let assignment = { ...formatBackendData(data) };
        return assignment;
      });
      const { orderPosition, ...finalData } = this.filterNullValues(backendUploadDatas[0]);
      const dataToUpdate = {
        ...finalData
      }
      return { dataToUpdate, requestQuery };
    },
    async handleAssignmentsProcessing() {
      console.log(this.$refs.newAssignmentItem[0].updatedDubEditor)
      if (this.user.adminDetails.dubEditorAccess && this.$refs.newAssignmentItem[0].updatedDubEditor) {
        this.$refs.newAssignmentItem[0].updateField(this.$refs.newAssignmentItem[0].updatedDubEditor, "dubEditorAssignment");
      }
      if (this.user.user.userRole === "STAFF" && this.user.adminDetails.dubEditorAccess) {
        this.assignmentsProcessing = true;
        const response = await processDubEditor(this.newAssignmentDatas[0].dubEditorAssignment);
        if (response.status === 200) {
          this.handleFlash({ response: {data: {message: "Assignment updated successfully", statusCode: 200}}, show: true });
        }
        this.setNewAssignmentModalOpen(false);
        this.assignmentsProcessing = false;
        return;
      }
      let datesChecker = true;
      this.$refs.newAssignmentItem.forEach((el) => {
        const writingDate = new Date(el.writingDueDate + " " + el.writingDue);
        const editingDate = new Date(el.editingDueDate + " " + el.editingDue);
        if (writingDate && editingDate && editingDate <= writingDate) {
          this.handleFlash({ response: {data: {message: "Secondary Due Date cannot be before Primary Due Date"}}, show: true });
          el.editingDueDate = null;
          el.editingDue = null;
          datesChecker = false;
        }
        if (el.reviewingDueDate && el.reviewingDue) {
          const reviewingDate = new Date(el.reviewingDueDate + " " + el.reviewingDue);
          if (reviewingDate && reviewingDate <= editingDate || reviewingDate <= writingDate) {
            this.handleFlash({ response: {data: {message: "QC Due Date cannot be before Secondary Due Date"}}, show: true });
            el.reviewingDueDate = null;
            el.reviewingDue = null;
            datesChecker = false;
          }
        }
      });
      if (!datesChecker) return;
      this.$refs.newAssignmentItem.forEach((el) => el.$refs.titleBox.blur());
      setTimeout(() => {
        if (this.confirmFormValid()) {
          if (this.bulkEditing) {
            this.setUpdateBatchConfirmation(true);
            return;
          }
          this.assignmentsProcessing = true;
          processAssignmentsWithDatas(
            this.newAssignmentDatas
          ).then((resp) => {
            this.$emit("assignment-dashboard-table-refresh");
            if (this.bulkEditing) this.setSelectedDashboardRows([]);
            const cueID =
              this.$moment(this.newAssignmentDatas[0].schedule.requestDate) +
              "_" +
              this.newAssignmentDatas[0].assignmentDetails.taskId;
            // handle task status after upload
            getAssignments({ query: { search: cueID } }).then((assgnResp) => {
              const assgnsWithCueIdField = assgnResp.content.filter(
                (el) => el.cueTaskId === cueID
              );
              if (
                assgnsWithCueIdField.length &&
                assgnsWithCueIdField.every(
                  (el) => el.assignmentDetails.assignmentStatus === "Assigned"
                )
              ) {
                getTasks({ query: { search: cueID } }).then((taskResp) => {
                  const task = taskResp.content.find(
                    (el) => el.cueTaskId === cueID
                  );
                  if (task) {
                    processTask({
                      data: {
                        taskStatus: "Assigned",
                      },
                      id: task.id,
                      patch: true,
                    }).then((resp) => {
                      this.setNewAssignmentModalOpen(false);
                      this.assignmentsProcessing = false;
                      this.handleFlash({ response: resp, show: true });
                    });
                  } else {
                    this.setNewAssignmentModalOpen(false);
                    this.assignmentsProcessing = false;
                    this.handleFlash({ response: resp, show: true });
                  }
                });
              } else {
                this.setNewAssignmentModalOpen(false);
                this.assignmentsProcessing = false;
                this.handleFlash({ response: resp, show: true });
              }
            });
          });
        }
      }, 100);
    },
    handleAssignmentsUpdate() {
      this.$refs.newAssignmentItem.forEach((el) => el.$refs.titleBox.blur());
      setTimeout(() => {
        if (this.confirmFormValid()) {
          this.assignmentsProcessing = true;
          let bulkEditBatch = [];
          if (this.bulkEditing && this.bulkQueryEdit) {
            const { dataToUpdate, requestQuery } = this.bulkQueryEditDataPrepare();
            updateAssignmentsList({payload: dataToUpdate, requestQuery}).then((resp) => {
              this.handleFlash({ response: resp, show: true });
              this.setNewAssignmentModalOpen(false);
              this.assignmentsProcessing = false;
              this.$emit("assignment-dashboard-table-refresh");
              this.setSelectedDashboardRows([]);
              getAssignments({ query: { ppm: this.$moment().format("MMMM").toUpperCase(), ppy: this.$moment().format("YYYY"), page: 0, size: 50, sort: "schedule_deliveryDate,desc" } });
            });
            return;
          }
          if (this.bulkEditing)
            this.updateConfirmation = true;
            bulkEditBatch = this.selectedDashboardRows.map((el) => ({
              id: el.id,
              ...this.newAssignmentDatas[0],
            }));
          processAssignmentsWithDatas(
            this.bulkEditing ? bulkEditBatch : this.newAssignmentDatas
          ).then((resp) => {
            this.$emit("assignment-dashboard-table-refresh");
            if (this.bulkEditing) this.setSelectedDashboardRows([]);
            const cueID =
              this.$moment(this.newAssignmentDatas[0].schedule.requestDate) +
              "_" +
              this.newAssignmentDatas[0].assignmentDetails.taskId;
            // handle task status after upload
            getAssignments({ query: { search: cueID } }).then((assgnResp) => {
              const assgnsWithCueIdField = assgnResp.content.filter(
                (el) => el.cueTaskId === cueID
              );
              if (
                assgnsWithCueIdField.length &&
                assgnsWithCueIdField.every(
                  (el) => el.assignmentDetails.assignmentStatus === "Assigned"
                )
              ) {
                getTasks({ query: { search: cueID } }).then((taskResp) => {
                  const task = taskResp.content.find(
                    (el) => el.cueTaskId === cueID
                  );
                  if (task) {
                    processTask({
                      data: {
                        taskStatus: "Assigned",
                      },
                      id: task.id,
                      patch: true,
                    }).then((resp) => {
                      this.setNewAssignmentModalOpen(false);
                      this.assignmentsProcessing = false;
                      this.handleFlash({ response: resp, show: true });
                    });
                  } else {
                    this.setNewAssignmentModalOpen(false);
                    this.assignmentsProcessing = false;
                    this.handleFlash({ response: resp, show: true });
                  }
                });
              } else {
                this.setNewAssignmentModalOpen(false);
                this.assignmentsProcessing = false;
                this.handleFlash({ response: resp, show: true });
              }
            });
          });
        }
        this.updateConfirmation = false;
      }, 100);
    },
    handleAssignmentsDeletion() {
      this.deleteConfirmation = false;
      this.deletionProcessing = true;
      deleteAssignment(this.newAssignmentDatas[0].id).then((resp) => {
        this.$emit("assignment-dashboard-table-refresh");
        this.setNewAssignmentModalOpen(false);
        this.deletionProcessing = false;
        this.handleFlash({ response: resp, show: true });
      });
    },
    handleExportToGoogle() {
      if (this.confirmFormValid()) {
        this.assignmentsProcessing = true;
        addRowsToGoogleSheet({ tableRows: this.newAssignmentDatas })
          .then(() => {
            this.assignmentsProcessing = false;
            window.open(
              `https://docs.google.com/spreadsheets/d/${SPREADSHEET_ID}/edit#gid=71384832`
            );
          })
          .catch((err) => {
            console.error(err);
            this.assignmentsProcessing = false;
          });
      }
    },
  },
};
</script>
