// libraries
import _ from "lodash";
// internal
import {
  getTableDataFromSource,
  getStaffMapFromRows,
} from "../utils/spreadsheetUploadUtils";
import { BLANK_FORM_VALUES } from "@/utils/spreadsheetUploadConstants";

export const spreadsheetUploadModule = {
  state: () => ({
    sourceFile: null,
    files: [],
    tableRows: null,
    allSourceFilesFound: false,
    formValues: BLANK_FORM_VALUES,
    staffAssignments: [],
    selectedDashboardRows: [],
    capacityPool: [],
    defaultCapacityPool: [],
    adjustedCapacityPool: [],
    staffLoading: false,
    xContent: false,
    shakespeare: false,
    shakespeareLoc: false,
    backstageCopywriting: false,
    cueLoc: false,
    cueCopywriting: false,
    baseLocalization: false,
    applyLocMinRate: false,
    prepopulationFlow: null,
    splitByPersonFilesAmount: 0,
    splitByPersonSend: 0,
  }),
  mutations: {
    ADD_FILE(state, file) {
      state.files.push(file);
    },
    REMOVE_FILE(state, fileName) {
      state.files = state.files.filter((f) => f.name !== fileName);
    },
    SET_FILES(state, files) {
      state.files = files;
    },
    ADD_SOURCE_FILE(state, file) {
      state.sourceFile = file;
    },
    REMOVE_SOURCE_FILE(state) {
      state.sourceFile = null;
    },
    CHANGE_TABLE_ROWS(state, rows) {
      state.tableRows = rows;
      rows
        ? rows[0].keyword === "MAS" ||
          rows[0].keyword === "Today" ||
          rows[0].hasOwnProperty("content_id") ||
          rows[0].hasOwnProperty("workType")
          ? (state.xContent = false)
          : (state.xContent = true)
        : (state.xContent = false);
    },
    SET_ALL_SOURCE_FILES_FOUND(state, allSourceFilesFound) {
      state.allSourceFilesFound = allSourceFilesFound;
    },
    SET_STAFF_ASSIGNMENTS(state, staffAssignments) {
      state.staffAssignments = staffAssignments;
    },
    UPDATE_STAFF_ASSIGNMENT_WORD_COUNT(state, { taskName, newWordCount }) {
      state.staffAssignments[taskName].wordCount = newWordCount;
    },
    SET_STAFF_ASSIGNMENT_OVERLOADING(state, { taskName, geo, key, value }) {
      let assignment;
      Object.entries(state.staffAssignments).forEach((el) => {
        if (el[1].hasOwnProperty(geo) && el[1].task_name === taskName)
          assignment = el[0];
      });
      Object.assign(
        state.staffAssignments[
          state.shakespeare || state.cueCopywriting ? assignment : taskName
        ][geo],
        { [key]: value }
      );
    },
    SET_FORM_VALUE(state, { key, value }) {
      const newFormValues = _.cloneDeep(state.formValues);
      newFormValues[key] = value;
      state.formValues = newFormValues;
    },
    RESET_FORM_VALUES(state) {
      state.formValues = BLANK_FORM_VALUES;
    },
    SET_SELECTED_DASHBOARD_ROWS(state, selectedDashboardRows) {
      state.selectedDashboardRows = selectedDashboardRows;
    },
    UPDATE_CAPACITY_DATA(state, { dataPiece, newData }) {
      state[dataPiece] = [...newData];
    },
    SET_STAFF_LOADING(state, staffLoading) {
      state.staffLoading = staffLoading;
    },
    SET_SHAKESPEARE(state, isShakespeare) {
      state.shakespeare = isShakespeare;
    },
    SET_SHAKESPEARE_LOC(state, isShakespeareLoc) {
      state.shakespeareLoc = isShakespeareLoc;
    },
    SET_COPYWRITING(state, isCopywriting) {
      state.backstageCopywriting = isCopywriting;
    },
    SET_CUE_LOC(state, isCueLoc) {
      state.cueLoc = isCueLoc;
    },
    SET_CUE_COPYWRITING(state, isCueCopywriting) {
      state.cueCopywriting = isCueCopywriting;
    },
    SET_BASE_LOCALIZATION(state, isBaseLocalization) {
      state.baseLocalization = isBaseLocalization;
    },
    SET_APPLY_LOC_MIN_RATE(state, isApplyLocMinRate) {
      state.applyLocMinRate = isApplyLocMinRate;
    },
    SET_PREPOPULATION(state, flow) {
      state.prepopulationFlow = flow;
    },
    SET_SPLIT_BY_PERSON_FILES_AMOUNT(state, amount) {
      state.splitByPersonFilesAmount = amount;
    },
    SET_SPLIT_BY_PERSON_SEND(state, amount) {
      state.splitByPersonSend = amount;
    }
  },
  actions: {
    addFile(context, file) {
      context.commit("ADD_FILE", file);
    },
    removeFile(context, fileName) {
      context.commit("REMOVE_FILE", fileName);
    },
    setFiles(context, files) {
      context.commit("SET_FILES", files);
    },
    addSourceFile(context, file) {
      context.commit("RESET_FORM_VALUES");
      context.commit("ADD_SOURCE_FILE", file);
      getTableDataFromSource(file).then((data) => {
        const staffMap = getStaffMapFromRows(data);
        context.commit("SET_STAFF_ASSIGNMENTS", staffMap);
        context.commit("CHANGE_TABLE_ROWS", data);
      });
    },
    removeSourceFile(context) {
      context.commit("RESET_FORM_VALUES");
      context.commit("REMOVE_SOURCE_FILE");
      context.commit("CHANGE_TABLE_ROWS", null);
    },
    setAllSourceFilesFound(context, allSourceFilesFound) {
      context.commit("SET_ALL_SOURCE_FILES_FOUND", allSourceFilesFound);
    },
    setFormValue(context, { key, value }) {
      context.commit("SET_FORM_VALUE", { key, value });
    },
    resetFormValues(context) {
      context.commit("RESET_FORM_VALUES");
    },
    setStaffAssignments(context, staffAssignments) {
      context.commit("SET_STAFF_ASSIGNMENTS", staffAssignments);
    },
    updateStaffAssignmentWordCount(context, { taskName, newWordCount }) {
      context.commit("UPDATE_STAFF_ASSIGNMENT_WORD_COUNT", {
        taskName,
        newWordCount,
      });
    },
    setStaffAssignmentOverloading(context, { taskName, geo, key, value }) {
      context.commit("SET_STAFF_ASSIGNMENT_OVERLOADING", {
        taskName,
        geo,
        key,
        value,
      });
    },
    setSelectedDashboardRows(context, selectedDashboardRows) {
      context.commit("SET_SELECTED_DASHBOARD_ROWS", selectedDashboardRows);
    },
    updateCapacityData(context, { dataPiece, newData }) {
      context.commit("UPDATE_CAPACITY_DATA", { dataPiece, newData });
    },
    setStaffLoading(context, staffLoading) {
      context.commit("SET_STAFF_LOADING", staffLoading);
    },
    setShakespeare(context, isShakespeare) {
      context.commit("SET_SHAKESPEARE", isShakespeare);
    },
    setShakespeareLoc(context, isShakespeareLoc) {
      context.commit("SET_SHAKESPEARE_LOC", isShakespeareLoc);
    },
    setCopywriting(context, isCopywriting) {
      context.commit("SET_COPYWRITING", isCopywriting);
    },
    setCueLoc(context, isCueLoc) {
      context.commit("SET_CUE_LOC", isCueLoc);
    },
    setCueCopywriting(context, isCueCopywriting) {
      context.commit("SET_CUE_COPYWRITING", isCueCopywriting);
    },
    setBaseLocalization(context, isBaseLocalization) {
      context.commit("SET_BASE_LOCALIZATION", isBaseLocalization);
    },
    setApplyLocMinRate(context, isApplyLocMinRate) {
      context.commit("SET_APPLY_LOC_MIN_RATE", isApplyLocMinRate);
    },
    setPrepopulation(context, flow) {
      context.commit("SET_PREPOPULATION", flow);
    },
    setSplitByPersonFilesAmount(context, amount) {
      context.commit("SET_SPLIT_BY_PERSON_FILES_AMOUNT", amount);
    },
    setSplitByPersonSend(context, amount) {
      context.commit("SET_SPLIT_BY_PERSON_SEND", amount);
    }
  },
  getters: {
    files: (state) => {
      return state.files;
    },
    sourceFile: (state) => {
      return state.sourceFile;
    },
    tableRows: (state) => {
      return state.tableRows;
    },
    allSourceFilesFound: (state) => {
      return state.allSourceFilesFound;
    },
    formValues: (state) => {
      return state.formValues;
    },
    staffAssignments: (state) => {
      return state.staffAssignments;
    },
    selectedDashboardRows: (state) => {
      return state.selectedDashboardRows;
    },
    capacityPool: (state) => {
      return state.capacityPool;
    },
    defaultCapacityPool: (state) => {
      return state.defaultCapacityPool;
    },
    adjustedCapacityPool: (state) => {
      return state.adjustedCapacityPool;
    },
    staffLoading: (state) => {
      return state.staffLoading;
    },
    xContent: (state) => {
      return state.xContent;
    },
    shakespeare: (state) => {
      return state.shakespeare;
    },
    shakespeareLoc: (state) => {
      return state.shakespeareLoc;
    },
    backstageCopywriting: (state) => {
      return state.backstageCopywriting;
    },
    cueLoc: (state) => {
      return state.cueLoc;
    },
    cueCopywriting: (state) => {
      return state.cueCopywriting;
    },
    baseLocalization: (state) => {
      return state.baseLocalization;
    },
    applyLocMinRate: (state) => {
      return state.applyLocMinRate;
    },
    wordCountValidated: (state) => {
      const wordCountValidation = [];
      Object.values(state.staffAssignments).forEach((el) => {
        wordCountValidation.push(!!el.wordCount);
      });
      return wordCountValidation.every((value) => value === true);
    },
    prepopulationFlow: (state) => {
      return state.prepopulationFlow;
    },
    splitByPersonFilesAmount: (state) => {
      return state.splitByPersonFilesAmount;
    },
    splitByPersonSend: (state) => {
      return state.splitByPersonSend;
    }
  },
};
