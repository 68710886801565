<template>
  <v-card class="d-flex flex-column grey lighten-2 px-4 pt-4">
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      class="v-card v-sheet theme--light pa-4"
    >
      <v-container>
        <v-row class="my-0 d-flex align-center">
          <v-col
              cols="4"
              class="d-flex align-center mt-0 px-0 py-0 title"
          >
            <v-icon class="mb-6">mdi-format-list-bulleted</v-icon>
            <p class="form__title ml-2 mt-0 mb-7">New Project for</p>
          </v-col>
            <v-col
                cols="8"
                class="my-0 py-0"
            >
              <v-autocomplete
                outlined
                dense
                label="Client Name"
                item-text="name"
                return-object
                :items="clientsList"
                v-model="relatedClient"
                @change="handleRelatedClient($event, true)"
              ></v-autocomplete>
            </v-col>
        </v-row>
        <v-tabs v-model="tab" background-color="transparent" grow>
          <v-tab v-for="step in steps" :key="step">
              {{ step }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
            <v-tab-item>
                <v-container>
                  <v-row class="mt-1">
                    <v-col cols="12" sm="6" class="pb-0">
                      <v-text-field
                        outlined
                        label="Project Name"
                        v-model="project.name"
                        :rules="[rules.required]"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" class="pb-0">
                      <v-autocomplete
                        outlined
                        label="Project Manager"
                        :items="driList"
                        :item-text="fullName"
                        return-object
                        v-model="projectManager"
                        @change="handlePm"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row class="mt-1">
                    <v-col cols="12" sm="6" class="pb-0">
                      <v-autocomplete
                        outlined
                        label="Project Type"
                        :items="typesList"
                        v-model="project.projectType"
                        :rules="[rules.required]"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" class="pb-0">
                      <v-autocomplete
                        outlined
                        label="Status"
                        :items="statusesList"
                        v-model="project.projectStatus"
                        :rules="[rules.required]"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row class="mt-1">
                    <v-col cols="12" sm="6" class="pb-0">
                      <v-autocomplete
                        outlined
                        label="Checklist"
                        multiple
                        :items="checklist"
                        v-model="project.checklist"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" class="pb-0">
                      <v-text-field
                        outlined
                        label="Inquiry Email"
                        v-model="project.inquiryEmail"
                      />
                    </v-col>
                  </v-row>
                  <v-row class="mt-1">
                    <v-col cols="12" sm="6" class="pb-0">
                      <v-text-field
                        outlined
                        label="Slack Channel ID (Staff)"
                        v-model="project.slackChannelIdStaff"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" class="pb-0">
                      <v-text-field
                        outlined
                        label="Slack Channel ID (Admin)"
                        v-model="project.slackChannelIdAdmin"
                      />
                    </v-col>
                  </v-row>
                  <v-row class="mt-1">
                    <v-col cols="12" sm="6" class="pb-0">
                      <v-menu
                        v-model="estStartDateMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="formattedEstStartDate"
                            label="Start Date"
                            prepend-inner-icon="mdi-calendar"
                            outlined
                            readonly
                            :rules="[rules.required]"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          @input="estStartDateMenu = false"
                          v-model="project.estStartDate"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="6" class="pb-0">
                      <v-menu
                        v-model="estEndDateMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="formattedEstEndDate"
                            label="End Date"
                            prepend-inner-icon="mdi-calendar"
                            outlined
                            readonly
                            :rules="[rules.required]"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          @input="estEndDateMenu = false"
                          v-model="project.estEndDate"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row class="mt-1">
                    <v-col cols="12" sm="6" class="pb-0">
                      <v-file-input
                        outlined
                        label="SOW"
                        accept=".pdf"
                        truncate-length="20"
                        v-model="sowBlob"
                        :loading="sowLoading"
                        :rules="[rules.isPDF]"
                        @change="convertFile($event)"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" class="pb-0">
                      <v-text-field
                        outlined
                        label="External Tracking Document"
                        v-model="project.externalTrackingDocument"
                      />
                    </v-col>
                  </v-row>
                  <v-row class="mt-1">
                    <v-col cols="12" sm="6" class="pb-0">
                      <v-autocomplete
                        outlined
                        multiple
                        chips
                        deletable-chips
                        item-text="value"
                        return-object
                        label="Supported Request Types"
                        :items="gGetRequestTypes"
                        v-model="relatedRequestTypes"
                        :rules="[rules.required]"
                        @change="handleRequestTypeChange"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" class="pb-0">
                      <v-autocomplete
                        outlined
                        multiple
                        chips
                        deletable-chips
                        return-object
                        item-text="workTypeName"
                        label="Supported Work Types"
                        :items="gGetCustomerPriceList"
                        v-model="relatedWorkTypes"
                        :rules="[rules.required]"
                        @change="handleWorkTypeChange"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row class="mt-1">
                    <v-col cols="12" class="pb-0">
                      <v-textarea
                        outlined
                        label="Note"
                        auto-grow
                        rows="1"
                        v-model="project.description"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                  <v-row class="mt-1">
                    <v-col cols="12" class="py-0 relative">
                      <v-checkbox
                        label="DUBEDITOR"
                        class="mt-0"
                        :value="project.dubEditorProject"
                        @click="handleCheckboxChange"
                      ></v-checkbox>
                      <div 
                        class="dub-cover" 
                        v-if="project.dubEditorProject"
                        @click="handleCheckboxChange">
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
            </v-tab-item>

            <v-tab-item>
              <v-container>
                <v-row class="mt-1">
                  <v-col cols="12" sm="4" class="pb-0">
                    <v-text-field
                      outlined
                      label="Project Owner Name"
                      v-model="project.projectOwnerName"
                    />
                  </v-col>
                  <v-col cols="12" sm="4" class="pb-0">
                    <v-text-field
                      outlined
                      label="Owner Role"
                      v-model="project.projectOwnerRole"
                    />
                  </v-col>
                  <v-col cols="12" sm="4" class="pb-0">
                    <v-text-field
                      outlined
                      label="Owner Email"
                      v-model="project.projectOwnerEmail"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="4" class="pb-0">
                    <v-text-field
                      outlined
                      label="Billing Contact Name"
                      v-model="project.billingContactName"
                    />
                  </v-col>
                  <v-col cols="12" sm="4" class="pb-0">
                    <v-text-field
                      outlined
                      label="Billing Contact Role"
                      v-model="project.billingContactRole"
                    />
                  </v-col>
                  <v-col cols="12" sm="4" class="pb-0">
                    <v-text-field
                      outlined
                      label="Billing Contact Email"
                      v-model="project.billingContactEmail"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="4" class="pb-0">
                    <v-text-field
                      outlined
                      label="Project Contact 1 Name"
                      v-model="project.projectContact1Name"
                    />
                  </v-col>
                  <v-col cols="12" sm="4" class="pb-0">
                    <v-text-field
                      outlined
                      label="Project Contact 1 Role"
                      v-model="project.projectContact1Role"
                    />
                  </v-col>
                  <v-col cols="12" sm="4" class="pb-0">
                    <v-text-field
                      outlined
                      label="Project Contact 1 Email"
                      v-model="project.projectContact1Email"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="4" class="pb-0">
                    <v-text-field
                      outlined
                      label="Project Contact 2 Name"
                      v-model="project.projectContact2Name"
                    />
                  </v-col>
                  <v-col cols="12" sm="4" class="pb-0">
                    <v-text-field
                      outlined
                      label="Project Contact 2 Role"
                      v-model="project.projectContact2Role"
                    />
                  </v-col>
                  <v-col cols="12" sm="4" class="pb-0">
                    <v-text-field
                      outlined
                      label="Project Contact 2 Email"
                      v-model="project.projectContact2Email"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12"class="pb-0">
                    <v-autocomplete
                      outlined
                      label="Client Requesters"
                      :item-text="fullName"
                      return-object
                      multiple
                      :disabled="!relatedClient"
                      :items="filteredClientRequesters"
                      :search-input.sync="clientRequestersSearchInput"
                      v-model="relatedClientRequesters"
                      @change="clientRequestersSearchInput = ''"
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>

            <v-tab-item>
              <v-container>
                <v-row>
                  <v-col cols="12" class="pb-0">
                    <v-autocomplete
                      outlined
                      multiple
                      chips
                      deletable-chips
                      label="GEOs"
                      item-text="key"
                      return-object
                      :items="gGetGeosList"
                      :search-input.sync="geosSearchInput"
                      v-model="relatedGeos"
                      @change="handleGeos"
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="pb-0">
                    <v-autocomplete
                      outlined
                      multiple
                      chips
                      deletable-chips
                      label="Staff"
                      :item-text="fullName"
                      return-object
                      :items="staff"
                      :search-input.sync="staffSearchInput"
                      v-model="relatedStaff"
                      @change="handleStaff"
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>

            <v-tab-item v-if="project.dubEditorProject">
              <v-container>
                <v-row class="mt-1">
                  <v-col cols="12" class="pb-0">
                    <v-text-field
                      outlined
                      hide-details
                      label="Style Guide"
                      v-model="dubEditor.styleGuide"
                    />
                  </v-col>
                </v-row>
                <v-row v-for="(fragment, index) in sortedFragments" :key="index">
                  <v-col cols="11" class="pb-0">
                    <v-col cols="12" class="pb-0 pl-0">
                      <v-text-field
                        outlined
                        hide-details
                        label="Fragment Name"
                        v-model="fragment.fragmentName"
                      />
                    </v-col>
                    <v-col cols="12" class="pb-0 pl-0">
                      <v-row>
                        <v-col cols="12" sm="4" class="pb-0">
                          <v-text-field
                            outlined
                            label="Min."
                            type="number"
                            :rules="[rules.nonNegative]"
                            @input="validateMinValue(index)"
                            v-model="fragment.min"
                          />
                        </v-col>
                        <v-col cols="12" sm="4" class="pb-0">
                          <v-text-field
                            outlined
                            label="Max."
                            type="number"
                            :rules="[rules.nonNegative]"
                            @input="validateMaxValue(index)"
                            v-model="fragment.max"
                          />
                        </v-col>
                        <v-col cols="12" sm="4" class="pb-0">
                          <v-select
                            outlined
                            :items="countOptions"
                            v-model="fragment.count"
                            label="Count"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-col>
                  <v-col cols="1" class="pb-0 d-flex align-center justify-center">
                    <v-btn
                      class="ma-1"
                      icon
                      height="40px"
                      @click="removeFragment(fragment.order)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12"class="pb-0 plus">
                    <button
                      class="plus-button"
                      @click.prevent="addNewFragment"
                    >
                      <v-icon class="plus-button--icon">mdi-plus</v-icon>
                    </button>
                    <span class="plus-button--text">Add fragment</span>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
        </v-tabs-items>
      </v-container>
    </v-form>
  <v-card-actions class="d-flex justify-end pr-4 pb-4">
      <v-btn
        depressed
        class="mx-3 grey lighten-2"
        :loading="projectHandling"
        @click="$emit('cancel')">
        Cancel
      </v-btn>
      <v-btn
        color="blue darken-2"
        class="request-btn mx-3 white--text"
        :loading="projectHandling"
        @click.prevent="handleProject"
        >
        <span v-if="!isEditMode">create</span>
        <span v-else>update</span>
      </v-btn>
    </v-card-actions>
    <v-dialog
      max-width="500"
      v-model="confirmationDubModal"
      @click:outside="confirmationDubModal = false"
      @keydown.esc="confirmationDubModal = false"
    >
      <div class="confirmation-modal">
        <p class="confirmation-modal--title" >Are you sure?</p>
        <p class="confirmation-modal--text">This will remove DubEditor from this project, and related assignments.</p>
        <div class="confirmation-modal--buttons d-flex align-center justify-end">
          <v-btn
            text
            @click="removeDubEditor"
            class="confirmation-modal--buttons-continue mr-2"
          >
            Ok
          </v-btn>
          <v-btn
            depressed
            color="#FF5252"
            @click="confirmationDubModal = false"
            class="confirmation-modal--buttons-discard"
          >
            Cancel
          </v-btn>
        </div>
      </div>
    </v-dialog>
  </v-card>
</template>

<script>
// vuex
import { mapActions, mapGetters } from "vuex";
// internal
import { getFile,
  processProject,
  deleteProject,
  getSpecificEnum,
  getCustomerPriceList,
  removeDubFragment
} from "@/utils/newDbUtils";
import { timeUnits } from "@/utils/mixins";

export default {
  name: "ProjectsForm",
  props: {
    itemToEdit: {
      type: Object,
      required: true,
    },
    clientsList: {
      type: Array,
      required: true,
    },
    statusesList: {
      type: Array,
      required: true,
    },
    staff: {
      type: Array,
      required: true,
    },
    typesList: {
      type: Array,
      required: true,
    },
    checklist: {
      type: Array,
      required: true,
    },
    prioritiesList: {
      type: Array,
      required: true,
    },
    driList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      steps: ["Main Info", "Client Info", "Staff & GEO"],
      tab: 0,
      staffSearchInput: "",
      geosSearchInput: "",
      relatedStaff: [],
      relatedGeos: [],
      relatedRequestTypes: [],
      relatedWorkTypes: [],
      estStartDateMenu: false,
      estEndDateMenu: false,
      relatedClient: {},
      clientRequestersSearchInput: "",
      filteredClientRequesters: [],
      relatedClientRequesters: [],
      projectManager: {},
      confirmationDubModal: false,
      project: {
        billingContactEmail: null,
        billingContactName: null,
        billingContactRole: null,
        checklist: [],
        geos: [],
        clientId: null,
        clientRequesterIds: [],
        description: null,
        estEndDate: null,
        estStartDate: null,
        externalTrackingDocument: null,
        inquiryEmail: null,
        name: null,
        priority: null,
        projectContact1Email: null,
        projectContact1Name: null,
        projectContact1Role: null,
        projectContact2Email: null,
        projectContact2Name: null,
        projectContact2Role: null,
        projectOwnerEmail: null,
        projectOwnerName: null,
        projectOwnerRole: null,
        projectManagerId: null,
        slackChannelIdStaff: null,
        slackChannelIdAdmin: null,
        projectStatus: null,
        projectType: null,
        role: null,
        role2: null,
        sow: null,
        staffIds: [],
        requestTypes: [],
        workTypes: [],
        dubEditorProject: false,
      },
      countOptions: [
        "words",
        "characters"
      ],
      dubEditor: {
        styleGuide: "",
        fragments: [
          {
            fragmentName: "",
            min: 0,
            max: 0,
            count: null,
            order: 1
          }
        ]
      },
      sowLoading: false,
      sowBlob: null,
      valid: true,
      projectHandling: false,
      deleteConfirmation: false,
      projectCopy: {},
      rules: {
        required: (value) => !!value || value === 0 || "Required",
        isPDF: (value) =>
          value?.type == "application/pdf" ||
          value === null ||
          "Only PDF attachments are allowed.",
        nonNegative: (value) => value >= 0 || "Value must be 0 or greater"
      },
    };
  },
  mixins: [timeUnits],
  computed: {
    ...mapGetters("projects", ["gGetRequestTypes", "gGetCustomerPriceList", "gGetGeosList"]),
    sortedFragments() {
      return [...this.dubEditor.fragments].sort((a, b) => a.order - b.order);
    },
    isEditMode() {
      return !!Object.keys(this.itemToEdit).length;
    },
    formattedEstStartDate: {
      get() {
        return this.formatPickerDate(this.project.estStartDate);
      },
      set() {
        this.project.estStartDate = "";
      },
    },
    formattedEstEndDate: {
      get() {
        return this.formatPickerDate(this.project.estEndDate);
      },
      set() {
        this.project.estEndDate = "";
      },
    }
  },
  watch: {
    itemToEdit() {
      this.dataViewsHandler();
    },
    relatedClientRequesters(newVal) {
      this.project.clientRequesterIds = newVal
        ? newVal.map((el) => el.user.id)
        : [];
    },
    'project.dubEditorProject': {
      deep: true,
      handler(value) {
        if (value && !this.steps.includes("dubeditor")) {
          this.steps.push("dubeditor");
          if (!this.dubEditor) this.dubEditor = {
            styleGuide: "",
            fragments: [
              {
                id: null,
                fragmentName: "",
                min: 0,
                max: 0,
                count: null,
                order: this.dubEditor?.fragments.length ? this.dubEditor?.fragments.length + 1 : 1
              }
            ]
          };
        }
        if (!value) {
          const elemIndex = this.steps.findIndex((step) => step === "dubeditor");
          if (elemIndex !== -1) {
            this.steps.splice(elemIndex, 1);
            this.dubEditor = null;
          }
        }
      }
    }
  },
  async mounted() {
    this.dataViewsHandler();
  },
  methods: {
    ...mapActions("flashMessage", ["handleFlash"]),
    handleCheckboxChange() {
      if (this.project.dubEditorProject) {
        this.confirmationDubModal = true;
      } else {
        this.project.dubEditorProject = !this.project.dubEditorProject;
      }
    },
    removeDubEditor() {
      this.project.dubEditorProject = false;
      this.confirmationDubModal = false;
    },
    validateMinValue(index) {
      if (this.dubEditor.fragments[index].min !== null && this.dubEditor.fragments[index].min < 0) {
        this.dubEditor.fragments[index].min = 0;
      }
    },
    validateMaxValue(index) {
      if (this.dubEditor.fragments[index].max !== null && this.dubEditor.fragments[index].max < 0) {
        this.dubEditor.fragments[index].max = 0;
      }
    },
    addNewFragment() {
      if (this.dubEditor && this.dubEditor.fragments.length < 10) {
        this.dubEditor.fragments.push({
          name: "",
          min: 0,
          max: 0,
          count: null,
          order: this.dubEditor.fragments.length ? this.dubEditor.fragments.length + 1 : 1
        });
        this.dubEditor.fragments = [...this.dubEditor.fragments]
      }
    },
    removeFragment(orderNumber) {
      if (this.dubEditor.fragments.length) {
        const index = this.dubEditor.fragments.findIndex((fragment) => fragment.order === orderNumber);
        const fragment = this.dubEditor.fragments.splice(index, 1);
        if (fragment[0].id) {
          const dubClone = _.cloneDeep(this.dubEditor.fragments);
          dubClone.sort((a, b) => a.order - b.order);
          dubClone.forEach((fragment, i) => fragment.order = i + 1);
          this.dubEditor.fragments = dubClone;
        }
      }
    },
    fullName(item) {
      return `${item?.user?.firstName} ${item?.user?.lastName}`;
    },
    handleStaff() {
      this.project.staffIds = this.relatedStaff.map((el) => el.user.id);
      this.staffSearchInput = "";
    },
    handleGeos() {
      this.project.geos = this.relatedGeos.map((el) => el.id);
      this.geosSearchInput = "";
    },
    handleRequestTypeChange() {
      this.project.requestTypes = this.relatedRequestTypes.map((el) => el.id);
    },
    handleWorkTypeChange() {
      this.project.workTypes = this.relatedWorkTypes.map((el) => el.id);
    },
    handlePm() {
      this.project.projectManagerId = this.projectManager?.user?.id ?? null;
    },
    handleRelatedClient(client, resetRequesters) {
      this.project.clientId = client?.id ?? null;
      this.filteredClientRequesters = this.relatedClient.clientRequesters?.sort(
        (a, b) => a.user.firstName.localeCompare(b.user.firstName)
      );
      this.relatedClientRequesters = resetRequesters
        ? []
        : this.filteredClientRequesters?.filter(
            (el) =>
              !!this.itemToEdit.clientRequesters.find(
                (item) => item.user.id === el.user.id
              )
          );
    },
    dataViewsHandler() {
      this.deleteConfirmation = false;
      if (this.isEditMode) this.handleEditMode();
      else {
        this.$refs.form.reset();
        // reset relational data
        this.project.clientId = null;
        this.project.clientRequesterIds = [];
        this.project.staffIds = [];
        this.project.geos = [];
        this.project.workTypes = [];
        this.project.requestTypes = [];
        this.project.description = null;
      }
    },
    handleEditMode() {
      this.project = { ...this.itemToEdit };
      this.projectCopy = _.cloneDeep(this.project);
      if (this.project.dubEditor) this.dubEditor = { ...this.project.dubEditor };
      this.projectManager =
        this.driList.find(
          (el) => el.user.id === this.itemToEdit.projectManager?.id
        ) ?? {};
      this.relatedClient = this.clientsList.find(
        (el) => el.id === this.itemToEdit.client?.id
      );
      this.relatedStaff = this.staff.filter((el) => {
        const staffIds = this.project?.staff.map((item) => item.user.id);
        if (staffIds.includes(el.user.id)) return el;
      });
      this.relatedGeos = this.gGetGeosList.filter((el) => {
        const geosIds = this.project?.geos;
        if (geosIds.includes(el.id)) return el;
      });
      this.relatedRequestTypes = this.gGetRequestTypes.filter((el) => {
        const requestTypesIds = this.project?.requestTypes;
        if (requestTypesIds.includes(el.id)) return el;
      });
      this.relatedWorkTypes = this.gGetCustomerPriceList.filter((el) => {
        const workTypesIds = this.project?.workTypes;
        if (workTypesIds.includes(el.id)) return el;
      });
      this.handleRelatedClient(this.itemToEdit.client, false);
      if (this.project.sow) this.handleSow();
      else this.sowBlob = null;
    },
    handleSow() {
      this.sowLoading = true;
      getFile({
        table: "project",
        file: "sow",
        entityId: this.itemToEdit?.id,
      }).then(async (resp) => {
        const base64Response = await fetch(
          `data:application/pdf;base64,${resp}`
        );
        const blob = await base64Response.blob();
        let newBlob = new Blob([blob], {
          type: "application/pdf",
        });
        newBlob.name = `${this.project.name}_SOW.pdf`;
        this.project.sow = ""; // indicates to backend the document don't needs to be updated
        this.sowBlob = newBlob;
        this.sowLoading = false;
      });
    },
    convertFile(file) {
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          this.project.sow = reader.result?.split(",")[1];
        };
        reader.readAsDataURL(file);
      } else {
        this.project.sow = null;
      }
    },
    async handleProject() {
      this.projectHandling = true;
      if (this.$refs.form.validate()) {
        this.project.projectManagerId = this.projectManager?.user?.id ? this.projectManager?.user?.id : null;
        if (this.isEditMode) {
          const idsToRemove = [];
          this.projectCopy?.dubEditor?.fragments && this.projectCopy.dubEditor.fragments.forEach((fragment) => {
            const item = this.dubEditor?.fragments?.length && this.dubEditor.fragments.find(
              (el) => el.id === fragment.id
            );
            if (!item) idsToRemove.push(fragment.id);
          })
          if (idsToRemove.length) {
            await Promise.all(
              idsToRemove.map((el) => {
                return removeDubFragment(el);
              })
            ).then(() => {
              console.log('removed')
            })
          }
        }
        processProject({
          data: { ...this.project, dubEditor: this.dubEditor },
          id: this.itemToEdit?.id,
        }).then((resp) => {
          this.projectHandling = false;
          this.$emit("project-table-refresh");
          this.handleFlash({ response: resp, show: true });
        });
      } else this.projectHandling = false;
    },
    deleteProj() {
      this.deleteConfirmation = false;
      this.projectHandling = true;
      deleteProject(this.itemToEdit?.id).then((resp) => {
        this.projectHandling = false;
        this.$emit("project-table-refresh");
        this.handleFlash({ response: resp, show: true });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.mt-25px {
  margin-top: 25px !important;
}
.plus {
  display: flex;
  align-items: center;
  &-button {
    background-color: #1976D2;
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    &--icon {
      color: #fff;
      font-size: 22px;
      font-weight: 700;
    }
    &--text {
      color: #00000099;
      font-family: Roboto;
      font-size: 12px;
      font-weight: 400;
      margin-left: 6px;
    }
  }
}
.confirmation-modal {
  background-color: #fff !important;
  width: 500px !important;
  display: flex;
  flex-direction: column;
  padding: 30px;
  &--title {
    color: #000000 !important;
    font-weight: 500 !important;
    font-size: 20px !important;
  }
  &--text {
    color: #000000 !important;
    font-weight: 300 !important;
    font-size: 14px !important;
  }
  &--buttons {
    margin-top: 20px;
    font-family: 'Roboto', sans-serif !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    &-discard {
      color: #fff !important;
    }
  }
}
.dub-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  &:hover {
    cursor: pointer;
  }
}
</style>
